import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
	Button,
	ButtonWithLoader,
	CloudinaryImage,
	Icon,
	Text,
} from "@website-builder/ui/shared/elements";
import { PRODUCTION_API_URL } from "@website-builder/ui/shared/constants";
import { TEXT_COLORS } from "@website-builder/utilities/constants/style-constants/colors.js";
import {
	formatColor,
	handleCTAClick,
	openTypeFormURLWithParameters,
} from "@website-builder/utilities/utils/DesignUtils.js";
import { isBusinessVerticalB2U } from "@website-builder/utilities/utils/helpers.js";
import { GetReferrer } from "@website-builder/utilities/utils/GetReferrer.js";
import {
	getTimeStamp,
	triggerHubspotCustomEventAPI,
} from "@website-builder/utilities/utils/helpers.js";
import {
	segmentIdentifyEvent,
	segmentTrackEvent,
} from "@website-builder/utilities/utils/SegmentEvents.js";
import { getVwoCampaignId } from "@website-builder/utilities/utils/VWO.js";
import { getUniqueUserId } from "@website-builder/utilities/utils/uniqueId.js";
import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
import {
	ButtonWrapper,
	FormHeaderWrapper,
	HubspotFormWrapper,
	SucessWrapper,
	ErrorSection,
} from "./styles";
import { callInterestedAPI, sendToHubspot, triggerZapier } from "./apiHandlers";
import {
	DESTINATIONS,
	ACTIVE_CAMPAIGN_FIELD_VALUES,
	HUBSPOT_DATA_EVENTS,
} from "./constants";
import { callEventAndRedirect } from "./utils";
import HSForm from "./HSForm";
import { callIdentifyEvent } from "@website-builder/utilities/utils/utils.js";

const isB2U = isBusinessVerticalB2U();
const isProductionBuild = process.env.GATSBY_ENVIRONMENT === "production";
const HubspotForm = (props) => {
	const {
		formDetails,
		context = {},
		pageSection = "",
		formData = [],
		isMobileView,
		itemsOnFirstColumn = 0,
		additionalItems = [],
		matchStyleToIframeForm = false,
	} = props;
	const styleConfiguration = formDetails?.styleConfiguration?.[0] || {};
	const { apiBaseUrl } = context;
	const {
		headline,
		subcopy,
		form,
		testForm,
		backgroundColor = {},
		consentText = "",
		destination = [DESTINATIONS.HUBSPOT],
		darkBg,
		// isLeadForm is only used in B2C to determine if interested API should be called on this stage
		isLeadForm = false,
		submitConfig: [submitConfig = {}] = [],
	} = formDetails;

	const {
		submitButton: [submitButtonConfig = {}] = [],
		successMessage: [successObj] = [],
		submitTriggers = [],
		actionOnSubmit,
		redirectLink: submitRedirectLink,
	} = submitConfig || {};
	let submitEvent = submitConfig?.submitEvent;

	const activeCampaignFieldValues = ACTIVE_CAMPAIGN_FIELD_VALUES;

	let formID = testForm;
	if (isProductionBuild || isB2U) {
		formID = form;
	}
	if (!formID) {
		console.error(
			"Can't load form as FormID was not found. Please review Storyblok config",
		);
	}

	const [formState, setFormState] = useState("input");
	const [email, setEmail] = useState("");
	const [buttonLoading, setButtonLoading] = useState(false);
	const headingColor = !darkBg
		? TEXT_COLORS.HEADING_DARK
		: TEXT_COLORS.HEADING_LIGHT;
	const textColor = !darkBg ? TEXT_COLORS.TEXT_DARK : TEXT_COLORS.TEXT_LIGHT;
	const isB2u = process.env.GATSBY_BUSINESS_VERTICAL === "b2u";
	const apiBaseUrlString = isB2u ? PRODUCTION_API_URL : apiBaseUrl;

	// content reference to enable scroll back to top
	const contentReference = useRef(null);

	const scrollIntoView = () => {
		contentReference?.current?.scrollIntoView({ behavior: "smooth" });
	};

	const handleFormSubmit = (formValues) => {
		setButtonLoading(true);
		const [referral, referral2] = GetReferrer();
		setEmail(formValues?.email);
		Object.entries(formValues).forEach(([key, value]) => {
			if (isB2U && key?.endsWith?.("referral")) {
				activeCampaignFieldValues[key] = value;
				formValues[key] = referral;
			}
			if (isB2U && key?.endsWith?.("referral1")) {
				activeCampaignFieldValues[key] = value;
				formValues[key] = referral2;
			}
			if (
				isB2U &&
				//Hubspot form is configured with vairationid instead of variationId
				(key?.endsWith("variationid") || key?.endsWith("variationId"))
			) {
				formValues[key] = getVwoCampaignId();
			}
		});
		const eventData = {
			page_section: pageSection,
			page_url: `${context.globalHostName}/${context.full_slug}`,
			name: `${formValues?.firstname} ${formValues?.lastname}`,
			email: formValues?.email,
			referral,
			referral2,
			form_id: formID,
			phone: formValues?.phone,
		};

		const redirectConfig = {
			actionOnSubmit,
			submitButtonConfig,
			submitRedirectLink,
			pageSection,
		};

		executeAPICallsAndRedirect(formValues, eventData, redirectConfig);

		/**
		 * This need to be revisited
		 */
		!isB2u &&
			triggerZapier(
				formValues,
				context,
				isProductionBuild,
				submitTriggers?.[0],
			);
		return true;
	};

	/**
	 * This function will be used to set contact in the window object
	 * @param {formValues} contains all the form values
	 */
	const setWindowContacts = (formValues) => {
		// Setting contact object in window to be used on the thank-you page
		window.contacts = {
			email: formValues?.email,
			firstname: formValues?.firstname,
			lastname: formValues?.lastname,
			phone: formValues?.phone,
		};
	};

	/**
	 * This function will be used to call hubspot custom event
	 * @param {formValues} contains all the form values
	 */
	const callHubspotCustomEvent = (formValues) => {
		const hsCustomAPIValues = {};
		Object.keys(formValues).forEach((key) => {
			if (
				!key.includes("referral") &&
				key !== "isleadform" &&
				key !== "checkbox"
			) {
				/**
				 * Here, we are filtering out values to send to hubspot via custom API
				 * All fields with 'referral' in their name are hidden fields.
				 * The 'checkbox' field is for T&C, and the 'isleadform' field is also hidden.
				 * These fields are not required by the API.
				 * All remaining fields (like email, preferred_course, phone, etc.) are sent.
				 */
				hsCustomAPIValues[key] = formValues[key];
			}
		});
		triggerHubspotCustomEventAPI({
			workshop_id: context?.story?.workshopId,
			...hsCustomAPIValues,
		});
	};

	const executeAPICallsAndRedirect = (
		formValues,
		eventData,
		redirectConfig = {},
	) => {
		// isleadform :: Property in Hubspot Form is only relevant for B2U. We don’t use these for B2C.
		const isLeadFormB2U = formValues?.isleadform || false;
		if (isB2U) {
			if (!isLeadFormB2U) {
				submitEvent = HUBSPOT_DATA_EVENTS.LEAD_FORM_SUBMIT;
			} else {
				submitEvent = HUBSPOT_DATA_EVENTS.SYLLABUS_DOWNLOAD;
				callHubspotCustomEvent(formValues);
			}
			setWindowContacts(formValues);
		}

		//Hubspot api call need to trigger for all b2c instance and for b2u if hubspot is selected in the destinations.
		const isSendHubspotData =
			destination?.some?.((ele) => ele === DESTINATIONS.HUBSPOT) || !isB2u;

		// Add email to redirectConfig to use it in typeform
		if (formValues?.email) {
			redirectConfig.email = formValues.email;
		}

		const functionCalls = [];
		isSendHubspotData &&
			functionCalls.push(
				sendToHubspot(
					apiBaseUrlString,
					context,
					formID,
					formValues,
					isLeadForm,
				),
			);
		isLeadForm &&
			functionCalls.push(
				callInterestedAPI(apiBaseUrlString, context, formID, formValues),
			);
		scrollIntoView();
		if (functionCalls.length) {
			callIdentifyEvent(eventData);
			Promise.all(functionCalls)
				.then((res) => {
					callEventAndRedirect(
						submitEvent || HUBSPOT_DATA_EVENTS.FORM_SUBMIT,
						eventData,
						redirectConfig,
					);
				})
				.catch((err) => {
					console.error("Error: ", err);
					let eventName = submitEvent
						? HUBSPOT_DATA_EVENTS.LEAD_FORM_SUBMIT_FAIL
						: HUBSPOT_DATA_EVENTS.FORM_SUBMIT_FAIL;
					/**
					 * isB2U is checked to see if we are in B2U flow,
					 * isLeadFormB2U is checked to see if Hubspot Form has isLeadForm property.
					 */
					if (isB2U && isLeadFormB2U) {
						eventName = HUBSPOT_DATA_EVENTS.SYLLABUS_DOWNLOAD_FAIL;
					}
					callEventAndRedirect(eventName, eventData, redirectConfig);
					setButtonLoading(false); // This line is useful in case callEventAndRedirect fails
				})
				.finally(() => {
					// Setting UI state to success even if API call fails to have a better UX.
					setFormState("success");
				});
		}
	};

	const handleSucessCtaClick = (cta) => {
		const [referral, referral2] = GetReferrer();
		if (isB2U && cta?.link?.type === "typeform") {
			const link = cta?.link?.link;
			const uuid = getUniqueUserId();
			const target = cta?.link?.target;
			const additionalParams = {
				cta_name: cta?.CTATrackingCode,
			};
			openTypeFormURLWithParameters(
				link,
				email,
				uuid,
				target,
				additionalParams,
			);
			return;
		}
		handleCTAClick(cta, {
			page_section: pageSection,
			redirect_to: cta.link?.link,
			click_text: cta.buttonText,
			referral,
			referral2,
		});
	};

	const marginBottomValue = !matchStyleToIframeForm ? "0.5rem" : "0";
	const formHeaderJSX = (
		<FormHeaderWrapper
			maxWidth={formDetails?.styleConfiguration?.[0]?.formWidth || "1000px"}
			isTextAlignmentLeft={formDetails?.isTextAlignmentLeft}
			className="form-header-wrapper"
		>
			{headline && (
				<Text
					variant="headline_4"
					style={{ marginBottom: marginBottomValue }}
					color={headingColor}
					className="heading-text"
				>
					{headline}
				</Text>
			)}
			{subcopy && (
				<Text
					tag="div"
					variant="paragraph_M"
					color={textColor}
					className="subcopy-text"
					dangerouslySetInnerHTML={{ __html: richTextResolver(subcopy) }}
				/>
			)}
		</FormHeaderWrapper>
	);

	const submitButtonJSX = (
		<ButtonWrapper>
			<ButtonWithLoader
				type="submit"
				buttonText={isB2U ? submitButtonConfig?.buttonText : "Submit"}
				className="submit-button"
				darkBg={darkBg}
				buttonConfiguration={submitButtonConfig}
				buttonLoading={buttonLoading}
			/>
		</ButtonWrapper>
	);

	switch (formState) {
		case "input":
			return (
				<HubspotFormWrapper
					bgColor={
						backgroundColor?.color ||
						formatColor(styleConfiguration?.backgroundColor)
					}
					styleConfiguration={styleConfiguration}
					darkBg={darkBg}
					isMobileView={isMobileView}
					isBoxShadow={!formDetails?.removeFormShadow}
					isTextAlignmentLeft={formDetails?.isTextAlignmentLeft}
					itemsOnFirstColumn={itemsOnFirstColumn}
					maxWidth={itemsOnFirstColumn ? "1000px" : "600px"}
					className="hubspot-form-wrapper"
					matchStyleToIframeForm={matchStyleToIframeForm}
				>
					{(headline || subcopy) && formHeaderJSX}
					<HSForm
						formID={formID}
						darkBg={darkBg}
						context={context}
						consentText={consentText}
						destination={destination}
						itemsOnFirstColumn={itemsOnFirstColumn}
						cachedFormData={formData}
						onSubmit={(formValues) => handleFormSubmit(formValues)}
					>
						{submitButtonJSX}
						{additionalItems.map((item, index) =>
							React.isValidElement(item) ? (
								<React.Fragment key={index}>{item}</React.Fragment>
							) : null,
						)}
					</HSForm>
				</HubspotFormWrapper>
			);
		case "success":
			return (
				<>
					{successObj ? (
						<SucessWrapper
							ref={contentReference}
							isBoxShadow={!formDetails?.removeFormShadow}
							isMobileView={isMobileView}
							className="hubspot-form-wrapper"
							bgColor={
								backgroundColor?.color ||
								formatColor(styleConfiguration?.backgroundColor)
							}
						>
							<div className="success-headline">
								<Icon iconName="checkCircle" size="32px" />
								<Text variant="headline_4" color={headingColor}>
									{successObj.headline}
								</Text>
							</div>
							{successObj?.subcopy ? (
								<Text
									tag="div"
									variant="paragraph_M"
									color={textColor}
									dangerouslySetInnerHTML={{
										__html: richTextResolver(successObj?.subcopy),
									}}
								/>
							) : null}
							{successObj.image?.image ? (
								<CloudinaryImage
									url={successObj.image.image}
									alt={successObj.image?.altTag}
									lazyload
									quality="auto"
									fetchFormat="auto"
									sizes={{ default: { width: 200 } }}
								/>
							) : null}
							{successObj.cta[0] ? (
								<Button
									buttonText={successObj.cta[0].buttonText}
									type={successObj.cta[0].type}
									onClick={() =>
										handleSucessCtaClick({
											...successObj?.cta?.[0],
											CTATrackingCode: successObj?.CTATrackingCode,
										})
									}
									submitButtonConfig={submitButtonConfig}
								/>
							) : null}
							{successObj?.bottomText ? (
								<Text
									tag="div"
									variant="paragraph_M"
									color={textColor}
									dangerouslySetInnerHTML={{
										__html: richTextResolver(successObj?.bottomText),
									}}
								/>
							) : null}
						</SucessWrapper>
					) : null}
				</>
			);
		case "error":
			return (
				<ErrorSection
					isBoxShadow={!formDetails?.removeFormShadow}
					isMobileView={isMobileView}
					className="hubspot-form-wrapper"
					bgColor={
						backgroundColor?.color ||
						formatColor(styleConfiguration?.backgroundColor)
					}
				>
					<Icon iconName="warning" size="32px" />
					<Text variant="headline_5" color={textColor}>
						Error submitting form!
					</Text>
				</ErrorSection>
			);
	}
};

HubspotForm.propTypes = {
	formDetails: PropTypes.object,
	itemsOnFirstColumn: PropTypes.number,
};

HubspotForm.defaultProps = {
	formDetails: {},
	itemsOnFirstColumn: 0,
};

export default HubspotForm;
